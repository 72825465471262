<template>
  <div class="main">
    <div class="row" v-if="!cardFormActive && (!newCardFinished && !newRowFinished)">
      <div class="numbers">
        <a href="#" @click.prevent="numberDrawn(i)" v-for="i in 90" v-bind:key="i" class="singleNumber"
           :class="{ active: this.numbers.indexOf(i) > -1 }">
          <span>{{ i }}</span>
        </a>
      </div>
      <div class="functions">
        <a href="#" class="btn" @click.prevent="reset">Reset</a><br />
        <a href="#" class="btn" @click.prevent="showCardForm(false)">Add Card</a>
      </div>
    </div>
    <div class="row" v-else-if="!newCardFinished && !newRowFinished">
      <div>
        <input type="number" v-model="cardFormNumbers1[i-1]" v-for="i in 5" v-bind:key="i" /><br />
        <input type="number" v-model="cardFormNumbers2[i-1]" v-for="i in 5" v-bind:key="i" /><br />
        <input type="number" v-model="cardFormNumbers3[i-1]" v-for="i in 5" v-bind:key="i" /><br />
        <a href="#" class="btn" @click.prevent="saveCard">Save</a>
        <a href="#" class="btn" @click.prevent="deleteCard" v-if="this.currentCard !== false">Delete</a>
      </div>
    </div>
    <div class="row fullwidth" v-else-if="newCardFinished">
      <div class="center">
        <span class="big">Carton!</span><br>
        <a href="#" class="btn" @click.prevent="closeCarton">close</a>
      </div>
    </div>
    <div class="row fullwidth" v-else-if="newRowFinished">
      <div class="center">
        <span class="big">LOTTO!</span><br>
        <a href="#" class="btn" @click.prevent="closeLotto">close</a>
      </div>
    </div>
    <div class="cards">
      <div class="card" v-for="(card, i) in this.cards" v-bind:key="i" @click.prevent="showCardForm(i)">
        <div class="cardrow" v-for="(cardrow, j) in card" v-bind:key="j">
          <div class="cardrownumber" v-for="(number, k) in cardrow" v-bind:key="k"
          :class="{ active: numbers.indexOf(number) > -1, last: numbers.indexOf(number) === numbers.length-1 && numbers.length > 0 }">
            {{number}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      'cards': [],
      'numbers': [],
      'cardFormActive': false,
      'cardFormNumbers1': [],
      'cardFormNumbers2': [],
      'cardFormNumbers3': [],
      newCardFinished: false,
      newRowFinished: false,
      currentCard: false,
    };
  },
  created() {
    let savedCards = localStorage.cards;
    if (savedCards !== undefined)
    {
      this.cards = JSON.parse(savedCards);
    }
    let savedNumbers = localStorage.numbers;
    if (savedNumbers !== undefined)
    {
      this.numbers = JSON.parse(savedNumbers);
    }
  },
  methods: {
    closeLotto() {
      this.newRowFinished = false;
    },
    closeCarton() {
      this.newCardFinished = false;
    },
    cardCount(cardRow) {
      let count = 0;
      cardRow.forEach((item) => {
        if (this.numbers.indexOf(item) > -1) {
          count++;
        }
      });
      return count;
    },
    numberDrawn(number) {
      const index = this.numbers.indexOf(number);
      if (index > -1) {
        this.numbers.splice(index, 1);
      } else {
        this.numbers.push(number);
      }
      localStorage.numbers = JSON.stringify(this.numbers);
      this.updateCards();
    },
    updateCards() {
      this.cards.forEach((card) => {
        let cardCount = 0;
        let cardContainsLast = false;
        card.forEach((row) => {
          let rowCount = 0;
          let rowContainsLast = false;
          row.forEach((item) => {
            const idx = this.numbers.indexOf(item);
            if (idx > -1) {
              rowCount++;
              cardCount++;
              if (idx === this.numbers.length -1) {
                rowContainsLast = true;
                cardContainsLast = true;
              }
            }
          });

          if (rowCount === 5 && rowContainsLast) {
            this.newRowFinished = true;
          }
        });
        if (cardCount === 15 && cardContainsLast) {
          this.newRowFinished = false;
          this.newCardFinished = true;
        }
      });
    },
    saveCard() {
      if (this.currentCard === false) {
        this.cards.push([
            this.cardFormNumbers1,
            this.cardFormNumbers2,
            this.cardFormNumbers3,
        ]);
      }
      localStorage.cards = JSON.stringify(this.cards);
      this.updateCards();
      this.cardFormActive = false;
    },
    deleteCard() {
      this.cards.splice(this.currentCard, 1);
      localStorage.cards = JSON.stringify(this.cards);
      this.cardFormActive = false;
    },
    reset() {
      this.numbers = [];
      localStorage.numbers = JSON.stringify(this.numbers);
      this.updateCards();
    },
    showCardForm(id) {
      this.cardFormNumbers1 = [];
      this.cardFormNumbers2 = [];
      this.cardFormNumbers3 = [];

      this.currentCard = id;
      if (id !== false) {
        this.cardFormNumbers1 = this.cards[id][0];
        this.cardFormNumbers2 = this.cards[id][1];
        this.cardFormNumbers3 = this.cards[id][2];
      }
      this.cardFormActive = true;
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  height: 99vh;
  width: 99vw;
  display: flex;
  flex-wrap: wrap;

  .row {
    display: flex;
    height: calc(99% - 300px);

    &.fullwidth {
      width: 99%;
      position: relative;

      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .big {
          font-size: 50px;
        }
      }
    }
  }

  input[type=number] {
    width: 40px;
  }

  .cards {
    width: 100%;
    height: 100px;
    display: flex;

    .card {
      border: 1px solid black;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .cardrow {
        display: flex;

        .cardrownumber {
          width: 20%;
          padding: 2px;
          font-size: 12px;
          text-align: right;

          &.active {
            background-color: lightgreen;
          }
          &.last {
            background-color: lightcoral;
          }
        }
      }
    }
  }
  .numbers {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 100px);

    .singleNumber {
      display: block;
      width: 10%;
      height: 11.11%;
      text-align: center;
      vertical-align: middle;
      color: black;
      text-decoration: none;
      position: relative;

      &.active {
        background-color: gray;
      }

      span {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: block;
      }
    }
  }
  .functions {
    flex-grow: 0;
    width: 100px;
    padding: 5px;
  }

  .btn {
    display: inline-block;
    color: black;
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: lightgray;
    text-decoration: none;
  }
}
</style>